@media (max-width: 600px) {
    .page {
        font-size: 0.8em;
    }
}

.title {
    font-size: 2em;
    font-weight: bold;
    margin-top: 0;
    margin-bottom: 0;
    text-align: center;
}

.subtitle {
    font-size: 1.5em;
    font-weight: normal;
    margin: 0 0 0.5em 0;
    text-align: center;
}

.voteCount {
    font-size: 1.2em;
    margin: 0 0 0.5em 0;
}

.page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.rankings {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 100vw;
}

.selection {
    margin: 1em;
}

.ranking {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 100vw;
    margin: 1em;
}

.senator {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 800px;
    max-width: 80%;
    margin: 1em;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.25);
    overflow: hidden;
}

.profile {
    width: 200px;
    max-width: 30vw;
}

.senatorInfo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    max-width: 100vw;
    margin: 1em;
}

.senatorName {
    font-size: 1.5em;
    font-weight: bold;
    margin: 0.5em;
}

.senatorDetail {
    font-size: 1.2em;
    margin: 0.5em;
}