.hotOrNot {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    padding: 0 2em;
}

@media (max-width: 600px) {
    .hotOrNot {
        font-size: 0.8em;
    }
}

.title {
    font-size: 2em;
    font-weight: bold;
    margin-top: 0;
    margin-bottom: 0;
    text-align: center;
}

.subtitle {
    font-size: 1.5em;
    font-weight: normal;
    margin: 0 0 0.5em 0;
    text-align: center;
}


.footnote {
    font-size: 1.0em;
    font-weight: normal;
    margin: 0 0 0.5em 0;
    text-align: center;
}
    
.senators {
    display: flex;
    flex-direction: row;
    gap: 5vw;
}

.senator {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0.5em;
    margin: 1em;
    border-radius: 4px;
    border: 1px solid #ccc;
    border-bottom: 2px solid #ccc;
    background-color: #ffffff;
}

.senator:hover {
    cursor: pointer;
    background-color: #eeeeee;
}

.senator:active {
    background-color: #dddddd;
    border-bottom: 1px solid #ccc;
}

.profile {
    width: 300px;
    max-width: 30vw;
    border-radius: 2px;
}

.skipButton {
    font-size: 1.5em;
    font-weight: bold;
    margin: 1em 1em 2em 1em;
    padding: 0.5em;
    border-radius: 4px;
    border: 1px solid #ccc;
    border-bottom: 2px solid #ccc;
    color: #333;
    background-color: #ffffff;
}

.skipButton:hover {
    cursor: pointer;
    background-color: #eeeeee;
}

.skipButton:active {
    background-color: #dddddd;
    border-bottom: 1px solid #ccc;
}
