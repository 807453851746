.header {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1em;
    width: 100%;
    margin: 0 auto;
    padding: 1em 0;
}

.headerLink {
    color: #000000;
    text-decoration: none;
    padding: 0.5em;
    border-radius: 4px;
    background-color: #ffffff;
    border: 1px solid #ccc;
    border-bottom: 2px solid #ccc;
}

.headerLink:hover {
    cursor: pointer;
    background-color: #eeeeee;
}

.headerLink:active {
    background-color: #dddddd;
    border-bottom: 1px solid #ccc;
}

.container {
    width: 100vw;
    max-width: 60em;
    margin: 0 auto;
    background-color: #fbfbfb;
    border-radius: 0 0 10px 10px;
    box-shadow: 0 0 10px rgba(0,0,0,.2);
}